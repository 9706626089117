/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    strong: "strong"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/technology/rechargeable/'], ['en', 'https://www.hear.com/hearing-aids/technology/rechargeable/'], ['en-US', 'https://www.hear.com/hearing-aids/technology/rechargeable/'], ['en-CA', 'https://ca.hear.com/hearing-aids/technology/rechargeable/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "rechargeable-hearing-aids-vs-rechargeable-batteries",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#rechargeable-hearing-aids-vs-rechargeable-batteries",
    "aria-label": "rechargeable hearing aids vs rechargeable batteries permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Rechargeable hearing aids v/s rechargeable batteries"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids technology has been constantly evolving, with new devices coming to the market every year. With more and more people realising they have hearing loss, the demand increases causing the manufacturers to come out with new products that meet the customer’s latest needs. We started with rechargeable batteries and now, the latest innovation is rechargeable hearing aids."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "rechargeable-batteries",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#rechargeable-batteries",
    "aria-label": "rechargeable batteries permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Rechargeable batteries"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids with rechargeable batteries have been around for a long while and they are an easy option compared to repeatedly purchasing new ones. Buying new ones can cost a lot in the long term. If you ever run out of batteries, then you have to wait until you buy new ones before using your hearing device. Rechargeable ones ensure you can charge them at night while you’re not using your hearing aids. Then put the batteries back in the morning and use the devices as usual."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "rechargeable-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#rechargeable-hearing-aids",
    "aria-label": "rechargeable hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Rechargeable hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Rechargeable hearing aids work similarly to the batteries. However, the difference is that rather than charging the batteries at night you would charge your actual devices. The hearing aids come with a charging pod that you can place your devices in at night, and then use them again in the day as normal. You can also carry the pod with you to charge on the go. Never miss out on a sound!"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "why-rechargeable-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-rechargeable-hearing-aids",
    "aria-label": "why rechargeable hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why rechargeable hearing aids?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Imagine you are in a hurry to get to work, you are already 20 minutes late. Just then, your hearing aids start indicating that you need to replace the batteries. When you go to check for the batteries, you don’t have any left. This is not only a waste of time but an extra hassle added to your day-to-day life. Rechargeable devices ensure that you don’t have to worry about that. You can recharge your devices every night and use them in the day. This saves time and you don’t have to worry about constantly spending money on new batteries. You can recharge your hearing aids for free! Another benefit of the rechargeable digital aid is that, people with limited dexterity don’t have to worry about changing small batteries which can be a problem for them."), "\n", React.createElement(ButtonCta, {
    copy: "TRY RECHARGEABLE HEARING AIDS",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "manufacturers-and-rechargeable-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#manufacturers-and-rechargeable-hearing-aids",
    "aria-label": "manufacturers and rechargeable hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Manufacturers and rechargeable hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We have come a long way from large behind-the-ear hearing aids to small in-the-ear digital hearing aids. However, this latest revolution isn’t produced by all manufacturers but there are still many options on the market. Signia is one of the biggest manufacturers to produce these rechargeable devices."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Signia")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Signia are one of the leading manufacturers in the industry and they have proved that yet again, by releasing the Signia Cellion. Siginia Cellion is a RIC (receiver in the canal) device with amazing up to date and high power technology. But, it has the added feature of recharging. The device comes with a charger that you can put the devices in. The smart technology means that the hearing aids turn themselves off when put in the charger and turn themselves on automatically, when removed from the charging case. This means the wearer doesn’t have to worry about anything other than putting them in their ears and taking them out to charge again."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "are-rechargeable-hearing-aids-cheap",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#are-rechargeable-hearing-aids-cheap",
    "aria-label": "are rechargeable hearing aids cheap permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Are rechargeable hearing aids cheap"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With latest technology comes higher price. Hearing aids are medical devices and to get the latest technology and good quality hearing experience you have to pay a little more than you pay for the cheap medical aids you might order online. The rechargeable digital aid is likely to be expensive but you will be saving money in the long run. As, you won’t have to spend money on getting new batteries every month. However, it is important to understand that a cheap hearing aid is likely to be second hand and may not function in the right way. When it comes to buying hearing aids, you should consult a specialist audiologist who can answer any questions you may have and help you choose a good quality device."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "rechargeable-hearing-aids-review",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#rechargeable-hearing-aids-review",
    "aria-label": "rechargeable hearing aids review permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Rechargeable hearing aids review"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The rechargeable hearing aids are suitable for all levels of hearing loss. So, no matter how severe or moderate your loss is, you can qualify for them. Currently, they are only available as BTE devices. However, they are quite discreet and small with the same advanced technology as the other hearing aids. So, you will still be getting the same high quality hearing experience.\nThe actual hearing aid is similar to the other models produced by the manufacturers. Therefore, the comfort while wearing the aids and ease of use remain the same. However, the charging option has been highly appreciated by customers. If the customers show further interest then the manufacturers will release further models with this feature, meaning people who want ITEs (In the ear) and CICs (Completely in Canal) would also have the option available to them."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Batteries are an important part of the hearing aid. This new technology takes away the hassle from wearing hearing aids. Now you can enjoy all the sounds without the worry."), "\n", React.createElement(ButtonCta, {
    copy: "TRY RECHARGEABLE HEARING AIDS",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
